import { useState } from "react";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import OfferController from "../../../../../assets/Controllers/OfferController";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import _OffersActionsLayout from "./_OffersActionsLayout";
import { useOfferNavigation } from "../../../../hooks/hooks";
import OfferDetails from "../../../../../assets/TakyonDesignSystem/components/TakDeal/OfferDetails";
import { openModal } from "../../../../../assets/Utils/modal";
import { DEFAULT_FEE_SPLIT } from "../../../../../config/Fees";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import helpers from "../../../../helpers/helpers";
import { useNavigate } from "react-router-dom";

export default function OfferAccept() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { offer } = useOfferNavigation();

  const acceptOfferClick = async () => {
    // if (
    //   (!user.stripeDetailsSubmitted || !user.stripeChargesEnabled) &&
    //   bid.mode == BID_MODE.MONEY
    // ) {
    //   openModal(<NoStripeError />, false);
    //   return;
    // }

    setIsLoading(true);
    try {
      await OfferController.acceptOffer({ bidId: offer._id });

      openModal({
        content: (
          <ActionFeedbackModal
            icon="check_circle"
            title={t("wallet.offers.yousuccessfullyaccepted")}
            message={`${t("wallet.offers.youaccepted")} ${helpers.price(
              !offer.counterOfferAmount || offer.counterOfferAmount === 0
                ? offer.amount
                : offer.counterOfferAmount
            )} ${t("wallet.offers.buyertime")}`}
            continueClickCallback={() => navigate("/profile/offers")}
            continueBtnText={t("app.checkout.viewoffers")}
          />
        ),
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_OffersActionsLayout title={t("wallet.offers.accept")}>
      <OfferDetails offer={offer} />
      <hr className="my-3" />

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            acceptOfferClick();
            //   id: "wallet_offer_page_success" + offer._id,
            //   content: <WalletOfferSuccess offer={offer} mode="accept" />,
            //   hideGoBack: true,
            // });
          }}
          text={t("wallet.offers.accept")}
          loading={isLoading}
        />
      </div>
    </_OffersActionsLayout>
  );
}
