import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import getLink, { LINK_TYPE } from "../../../config/Links";
import {
  Flags,
  ForgotPassword,
  Login,
  Register,
  RegisterPromo,
  ResetPassword,
  Retrieve,
  UserInfo,
  VerifyEmail,
} from "../../components/Auth/AuthComponents";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import UserController from "../../../assets/Controllers/UserController";
import {
  closeModal,
  openModal,
  setModalVariables,
} from "../../../assets/Utils/modal";
import NftController from "../../../assets/Controllers/NftController";
import { apiErrorToast } from "../../../assets/Utils/errors";
import Tab from "../../../assets/TakyonDesignSystem/components/Tab/Tab";
import { useTranslation } from "react-i18next";
import store from "../../../redux/redux";
import { setProfileMenu } from "../../../redux/slices/appSlice";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";

export enum AUTH_SECTION {
  login = "login",
  register_promo = "register_promo",
  register = "register",
  forgot_password = "forgot_password",
  reset_password = "reset_password",
  verify_email = "verify_email",
  reset_password_success = "reset_password_success",
  flags = "flags",
  info = "info",
}

(() => {
  const params = new URL(window.location.toString()).searchParams;
  const _promocode = params.get("promocode");
  if (_promocode) openAuthModal(AUTH_SECTION.register);
})();

export default function openAuthModal(
  mode: AUTH_SECTION = AUTH_SECTION.login,
  onSuccess?: Function,
  defaultReferralCode?: string
) {
  openModal(
    {
      content: (
        <Auth
          mode={mode}
          onSuccess={onSuccess}
          defaultReferralCode={defaultReferralCode}
        />
      ),
    },
    false,
    undefined,
    () => {
      store.dispatch(setProfileMenu(false));
    }
  );
}

export function Auth({
  mode,
  onSuccess,
  defaultReferralCode,
}: {
  mode: AUTH_SECTION;
  onSuccess?: Function;
  defaultReferralCode?: string;
}) {
  const user = useSelector(selectUser);

  const { t } = useTranslation();

  const [referralCode, setReferralCode] = useState(defaultReferralCode);
  const [promocode, setPromoCode] = useState("");
  const [_, setNftRetrieveId] = useState("");
  const [nftRetrieveHash, setNftRetrieveHash] = useState("");
  const [resetPasswordToken, setResetPasswordToken] = useState("");

  const navigate = useNavigate();

  const [authMode, setAuthMode] = useState<AUTH_SECTION>(mode);

  const tabItems = [
    { name: t("auth.login"), value: AUTH_SECTION.login },
    { name: t("auth.register"), value: AUTH_SECTION.register },
  ];

  const retrieve = async () => {
    try {
      await NftController.retrieveFromEmailHash({
        hash: nftRetrieveHash,
      });

      navigate(getLink(LINK_TYPE.PROFILE));
    } catch (error) {
      apiErrorToast(error);
    }
  };

  useEffect(() => {
    if (resetPasswordToken) {
      setAuthMode(AUTH_SECTION.reset_password);
      return;
    }

    if (user) {
      if (nftRetrieveHash) retrieve();

      if (!user.emailVerified) {
        setAuthMode(AUTH_SECTION.verify_email);
        setModalVariables({
          onBack: () => {
            UserController.logout();
            setAuthMode(AUTH_SECTION.login);
            setModalVariables({ onBack: () => {} });
          },
        });
        return;
      }

      if (typeof user.marketingEmails === "undefined") {
        setAuthMode(AUTH_SECTION.flags);
        setModalVariables({
          onBack: () => {
            UserController.logout();
            setAuthMode(AUTH_SECTION.login);
            setModalVariables({ onBack: () => {} });
          },
        });
        return;
      }

      if (
        !user.firstName ||
        !user.secondName ||
        !user.phone ||
        !user.phonePrefix ||
        !user.dateOfBirth
      ) {
        setAuthMode(AUTH_SECTION.info);
        setModalVariables({
          onBack: () => {
            UserController.logout();
            setAuthMode(AUTH_SECTION.login);
            setModalVariables({ onBack: () => {} });
          },
        });
        return;
      }

      closeModal();
      onSuccess?.();
    }
  }, [user, resetPasswordToken, nftRetrieveHash]);

  // Referral code effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const referralcode = params.get("referralcode");
    if (referralcode) setReferralCode(referralcode);
  }, []);

  // retrieve nft effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const nft = params.get("nftId");
    if (nft) setNftRetrieveId(nft);
    const hash = params.get("hash");
    if (hash) setNftRetrieveHash(hash);
  }, []);

  // reset password token effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const token = params.get("resetPasswordToken");
    if (token) setResetPasswordToken(token);
  }, []);

  // Promo code effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const _promocode = params.get("promocode");

    if (_promocode) setPromoCode(_promocode);

    (async () => {
      try {
        if (user && promocode) {
          const credits = await UserController.redeemCredits(promocode);

          setPromoCode("");

          openModal({
            content: (
              <>
                <div style={{ zIndex: 2 }} className="d-flex flex-column">
                  <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
                    <span style={{ height: "22px" }}></span>
                  </div>
                  <div className="modal-body d-flex flex-column align-items-center justify-content-center">
                    <div className="p-4 pt-5 d-flex flex-column align-items-center justify-content-center w100">
                      <Icon icon="check_circle" type="symbol" size={150} />
                    </div>
                    <div className="height-10"></div>
                    <div className="p-4 pt-0 pt-md-0 p-md-5">
                      <div className="d-flex flex-column gap-3 align-items-center">
                        <p className="m-0 h1 regular text-center">
                          {t("auth.promocode1")} {credits.amount}€{" "}
                          {t("auth.promocode2")}
                        </p>
                        <p className="m-0 bodytext regular text-center">
                          {t("auth.promocode3")} {_promocode?.toUpperCase()}{" "}
                          {t("auth.promocode4")} {credits.amount}€{" "}
                          {t("auth.promocode5")}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex w100 justify-content-center p-4 pt-0 pt-md-0 p-md-5">
                      <Button onClick={() => closeModal()}>Let's go!</Button>
                    </div>
                  </div>
                </div>
              </>
            ),
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user]);

  function onChangeTab(value: any) {
    setAuthMode(value);
    //setAuthMode(AUTH_SECTION.register_promo);
  }

  return (
    <>
      <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
        {authMode !== "register_promo" ? (
          <>
            <span className="bodytext medium">{t("auth.login_register")}</span>
          </>
        ) : (
          <div className="height-20"></div>
        )}
      </div>

      <div className="modal-body d-flex flex-column justify-content-between">
        <div className="px-4">
          {authMode === "login" || authMode === "register" ? (
            <>
              <div className="height-24"></div>
              <Tab size="50%" items={tabItems} onChange={onChangeTab}></Tab>
            </>
          ) : null}
        </div>
        {authMode === "login" ? (
          <Login
            onForgotPassword={() => {
              setAuthMode(AUTH_SECTION.forgot_password);
              setModalVariables({
                onBack: () => {
                  setAuthMode(AUTH_SECTION.login);
                  setModalVariables({ onBack: () => {} });
                },
              });
            }}
          />
        ) : null}
        {authMode === "register" ? (
          <Register referralCode={referralCode} />
        ) : null}
        {authMode === "register_promo" ? <RegisterPromo /> : null}
        {authMode === "verify_email" ? <VerifyEmail /> : null}
        {authMode === "flags" ? <Flags /> : null}
        {authMode === "info" ? (
          <UserInfo onBack={() => setAuthMode(AUTH_SECTION.login)} />
        ) : null}
        {authMode === "forgot_password" ? <ForgotPassword /> : null}
        {authMode === "reset_password" ? (
          <ResetPassword
            onBack={() => setAuthMode(AUTH_SECTION.login)}
            token={resetPasswordToken}
          />
        ) : null}
      </div>
    </>
  );
}
