import { FormEvent, useEffect, useState } from "react";
import {
  BuilderComponent,
  builder,
  useIsPreviewing,
  Builder,
} from "@builder.io/react";
import NotFound from "./_Shared/pages/NotFound/NotFound";
import PartnerController from "./assets/Controllers/PartnerController";
import { Form } from "react-bootstrap";
import Input from "./assets/TakyonDesignSystem/components/Input/Input";
import { handleInputChange } from "./assets/Utils/input";
import Button from "./assets/TakyonDesignSystem/components/Button/Button";
import SwipeController from "./assets/Controllers/SwipeController";
import Select from "./assets/TakyonDesignSystem/components/Select/Select";
import DatePicker from "./assets/TakyonDesignSystem/components/DatePicker/DatePicker";
import { date } from "yup";

// Put your API key here
builder.init(String(process.env.REACT_APP_BUILDER_IO_APIKEY));

const ReducedTellUsMoreForm = (params: {
  emailPlaceholder: string;
  phonePlaceholder: string;
  budgetPlaceholder: string;
  numberOfPeoplePlaceholder: string;
  regionPlaceholder: string;
  datesPlaceholder: string;
  specialRequestsPlaceholder: string;
  checkboxLabel: string;
  submitButton: string;
  thanks_title: string;
  thanks_text: string;
  continue: string;
}) => {
  const [phone, setPhone] = useState(""); // Optional phone number
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [region, setRegion] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false); // For the required checkbox
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Form validation: Email, Budget, Number of People, Dates, and Checkbox must be valid
    if (
      numberOfPeople.trim() !== "" &&
      checkboxChecked &&
      phone &&
      numberOfPeople.trim() !== "" &&
      specialRequests
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [numberOfPeople, checkboxChecked]);
  const sendForm = async () => {
    setIsLoading(true);
    try {
      await SwipeController.requestStay({
        phone, // Optional field
        numberOfPeople,
        specialRequests,
        region,
      });
      setIsFormSent(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  return (
    <>
      {isFormSent ? (
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center py-2">
            <span style={{ height: "22px" }}></span>
          </div>
          <div className="height-24"></div>
          <div className="d-flex flex-column justify-content-center h100 w100">
            <div className="height-10"></div>
            <span className="h2 white-color">{params.thanks_title}</span>
            <div className="height-10"></div>
            <span className="bodytext white-color">{params.thanks_text}</span>
            <div>
              <Button
                style={{ marginTop: "20px", marginBottom: "20px" }}
                text={params.continue}
                onClick={() => {
                  // Reset the form and close the modal or continue
                  setIsFormSent(false);
                  setPhone("");
                  setNumberOfPeople("1");
                  setSpecialRequests("");
                  setCheckboxChecked(false);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <form
          className="d-flex flex-column"
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            sendForm();
          }}
        >
          {/* Phone Number (optional) */}
          <Form.Group className="py-8">
            <Input
              type="text"
              placeholder={params.phonePlaceholder}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          {/* Number of People */}
          <Form.Group className="py-8">
            <Select
              value={numberOfPeople}
              onChange={(e) => setNumberOfPeople(e.target.value)}
              options={[
                { value: "", text: params.numberOfPeoplePlaceholder },
                { value: "mountain", text: "Avventura in montagna" },
                { value: "lake", text: "Relax al lago" },
                { value: "city", text: "Fuga in città" },
                { value: "sea", text: "Vacanze al mare" },
                { value: "experience", text: "Esperienze in giornata" },
              ]}
            />
          </Form.Group>
          <Form.Group className="py-8">
            <Select
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              options={[
                { value: "", text: params.regionPlaceholder },
                { value: "abruzzo", text: "Abruzzo" },
                { value: "basilicata", text: "Basilicata" },
                { value: "calabria", text: "Calabria" },
                { value: "campania", text: "Campania" },
                { value: "emilia-romagna", text: "Emilia-Romagna" },
                {
                  value: "friuli-venezia-giulia",
                  text: "Friuli-Venezia Giulia",
                },
                { value: "lazio", text: "Lazio" },
                { value: "liguria", text: "Liguria" },
                { value: "lombardia", text: "Lombardia" },
                { value: "marche", text: "Marche" },
                { value: "molise", text: "Molise" },
                { value: "piemonte", text: "Piemonte" },
                { value: "puglia", text: "Puglia" },
                { value: "sardegna", text: "Sardegna" },
                { value: "sicilia", text: "Sicilia" },
                { value: "toscana", text: "Toscana" },
                { value: "trentino-alto-adige", text: "Trentino-Alto Adige" },
                { value: "umbria", text: "Umbria" },
                { value: "valle-d-aosta", text: "Valle d'Aosta" },
                { value: "veneto", text: "Veneto" },
              ]}
            />
          </Form.Group>
          {/* Special Requests */}
          <Form.Group className="py-8">
            <Input
              type="text"
              placeholder={params.specialRequestsPlaceholder}
              value={specialRequests}
              onChange={(e) => setSpecialRequests(e.target.value)}
            />
          </Form.Group>
          {/* Checkbox (required) */}
          <Form.Group className="py-8">
            <div className="d-flex gap-2">
              <div>
                <input
                  type="checkbox"
                  id="checkbox"
                  checked={checkboxChecked}
                  onChange={() => setCheckboxChecked(!checkboxChecked)}
                />
              </div>
              <label
                className="white-color"
                htmlFor="checkbox"
                style={{ marginLeft: "8px" }}
              >
                {params.checkboxLabel}
              </label>
            </div>
          </Form.Group>
          {/* Submit Button */}
          <div className="d-flex justify-content-start mt-3">
            <Button
              type="submit"
              text={params.submitButton}
              disabled={!isFormValid}
              loading={isLoading}
            />
          </div>
        </form>
      )}
    </>
  );
};
// Register the ReducedTellUsMoreForm component with Builder.io
Builder.registerComponent(ReducedTellUsMoreForm, {
  name: "ReducedTellUsMoreForm4",
  inputs: [
    { name: "emailPlaceholder", type: "text" },
    { name: "phonePlaceholder", type: "text" }, // Added for phone number
    { name: "budgetPlaceholder", type: "text" },
    { name: "numberOfPeoplePlaceholder", type: "text" },
    { name: "datesPlaceholder", type: "text" },
    { name: "specialRequestsPlaceholder", type: "text" },
    { name: "checkboxLabel", type: "text" }, // Added for checkbox label
    { name: "submitButton", type: "text" },
    { name: "regionPlaceholder", type: "text" },
    { name: "thanks_title", type: "text" },
    { name: "thanks_text", type: "text" },
    { name: "continue", type: "text" },
  ],
});

export function BecomePartnerSmart(params: {
  emailPlaceholder: string;
  websitePlaceholder: string;
  phonePlaceholder: string;
  infotext: string;
  button: string;
  success: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [partnerInformation, setPartnerInformation] = useState<{
    phoneNumber: string;
    website: string;
    email: string;
  }>({
    phoneNumber: "",
    website: "",
    email: "",
  });
  const SendFormClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!partnerInformation.website || !partnerInformation.phoneNumber) return;
    setIsLoading(true);
    try {
      await PartnerController.requestPartnership({
        hotelName: partnerInformation.website,
        phoneNumber: partnerInformation.phoneNumber,
        email: partnerInformation.email,
      });
      setIsFormSent(true);
    } catch (error) {}
    setIsLoading(false);
  };
  return (
    <section className="web-section-container">
      {!isFormSent ? (
        <div className="web-section-content align-items-center">
          <form
            onSubmit={SendFormClick}
            style={{ width: "100%", gap: "0" }}
            id="become-partner-form-builder-io"
          >
            <Form.Group className="w100">
              <Input
                type="email"
                name="email"
                placeholder={params.emailPlaceholder}
                value={partnerInformation.email}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>
            <div className="height-12"></div>
            <Form.Group className="w100">
              <Input
                type="text"
                name="website"
                placeholder={params.websitePlaceholder}
                value={partnerInformation.website}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>
            <div className="height-12"></div>
            <Form.Group className="w100">
              {/* <Form.Label>{params.phoneLabel}</Form.Label> */}
              <Input
                type="number"
                name="phoneNumber"
                placeholder={params.phonePlaceholder}
                value={partnerInformation.phoneNumber}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>
            <div className="height-12"></div>
            <span className="bodytext-sm dark-grey-color regular">
              {params.infotext}
            </span>
            <div className="height-20"></div>
            <div className="">
              <Button
                text={params.button}
                type="submit"
                size="lg"
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="web-section-content align-items-center">
          <div>{params.success}</div>
        </div>
      )}
    </section>
  );
}
Builder.registerComponent(BecomePartnerSmart, {
  name: "BecomePartnerFormSmart3",
  inputs: [
    { name: "emailPlaceholder", type: "text" },
    { name: "websitePlaceholder", type: "text" },
    { name: "phonePlaceholder", type: "text" },
    { name: "infotext", type: "text" },
    { name: "button", type: "text" },
    { name: "success", type: "text" },
  ],
});

const MetamatchDetailsForm = (params: {
  detailsPlaceholder: string;
  submitButton: string;
  emailPlaceholder: string;
}) => {
  const [details, setDetails] = useState("");
  const [email, setEmail] = useState("");
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Form validation: Email, Budget, Number of People, Dates, and Checkbox must be valid
    if (details !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [details]);
  const sendForm = async () => {
    setIsLoading(true);
    try {
      await SwipeController.requestStay({
        details,
        email,
      });
      setDetails("");
      setEmail("");
      setIsFormSent(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  return (
    <>
      <form
        className="d-flex flex-column px-3"
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          sendForm();
        }}
      >
        <Form.Group className="w100">
          <Input
            type="email"
            name="email"
            placeholder={params.emailPlaceholder}
            value={email}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="py-8">
          <Input
            type="text"
            placeholder={params.detailsPlaceholder}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Form.Group>
        {/* Submit Button */}
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Button
            type="submit"
            text={params.submitButton}
            disabled={!isFormValid}
            loading={isLoading}
          />
        </div>
      </form>
    </>
  );
};
// Register the ReducedTellUsMoreForm component with Builder.io
Builder.registerComponent(MetamatchDetailsForm, {
  name: "MetamatchdetailsForm6",
  inputs: [
    { name: "detailsPlaceholder", type: "text" },
    { name: "emailPlaceholder", type: "text" },
    { name: "submitButton", type: "text" },
  ],
});

function CustomLink(props: any) {
  return <a {...props} />;
}
// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function BuilderIo() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname,
        })
        .promise();
      setContent(content);
      setNotFound(!content);
      // if the page title is found,
      // set the document title
      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, [window.location.pathname]);
  if (notFound && !isPreviewingInBuilder) {
    console.log("Builder.io : not Found");
    return (
      <div className="black-background">
        <NotFound />
      </div>
    );
  }
  if (!content) return <></>;
  // return the page when found
  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent
        model="page"
        content={content}
        renderLink={(props) => <CustomLink {...props} />}
      />
    </>
  );
}
