import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import getLink, { LINK_TYPE } from "../../../config/Links";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { SMALL_SCREEN, contentMaxWidth } from "../../../assets/Utils/generic";
import TakyonLight from "../../../assets/img/icons/TakyonLight.svg";
import { useTranslation } from "react-i18next";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import {
  getLocaleLanguage,
  setLocaleLanguage,
} from "../../../assets/Services/i18next";
import { LANG } from "../../../config/Lang";
import { footerItemsProps } from "../Footer/Footer";

import facebook from "../../../assets/img/icons/facebookGrey.svg";
import instagram from "../../../assets/img/icons/instagramGrey.svg";
import linkedin from "../../../assets/img/icons/linkedinGrey.svg";
import { useSelector } from "react-redux";
import {
  selectSellerPendingOffersCount,
  selectUser,
} from "../../../redux/slices/userSlice";
import AdminController from "../../../assets/Controllers/AdminController";
import TakyonColor from "../../../assets/TakyonDesignSystem/images/TakyonDark.svg";
import openAuthModal from "../Auth/Auth";
import {
  selectHeroVisibility,
  setProfileMenu,
  selectProfileMenuVisibility,
} from "../../../redux/slices/appSlice";
import { useAppDispatch } from "../../../redux/redux";
import {
  iWallConfig,
  iWallConfigObject,
  WALL_CONFIG,
} from "../../../config/wall";

import all from "../../../assets/img/categories/all.jpg";
import staycity from "../../../assets/img/categories/stay-city.jpg";
import stayexperience from "../../../assets/img/categories/stay-experience.jpg";
import staylake from "../../../assets/img/categories/stay-lake.jpg";
import staymountain from "../../../assets/img/categories/stay-mountains.jpg";
import staysea from "../../../assets/img/categories/stay-sea.jpg";
import staytrips from "../../../assets/img/categories/stay-trips.jpg";
import { openSearchModal } from "./SearchBox/SearchBox";
import BecomePartner from "../Body/BecomePartner/BecomePartner";
import { scrollTo } from "../../../assets/Utils/generic";
import { motion } from "framer-motion";

interface Props {
  nav?: boolean;
  lang?: LANG;
  setLang?: (lang: LANG) => void;
}

interface linksProps {
  title: string;
  link: string;
}

function NavMenu({ lang, setLang }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const isShortScreen = useMediaQuery({
    query: "only screen and (min-width : 769px) and (max-height : 600px)",
  });
  const isHeroVisible = useSelector(selectHeroVisibility);

  const user = useSelector(selectUser);

  const links: linksProps[] = [
    {
      title: t("nav.forclients"),
      link: getLink(LINK_TYPE.FORCLIENTS),
    },
  ];

  const support: footerItemsProps[] = [
    { path: "mailto:support@takyon.io", text: t("footer.contact") },
    { path: getLink(LINK_TYPE.SUPPORT), text: "FAQ" },
    { path: "mailto:support@takyon.io", text: "support@takyon.io" },
  ];

  const follow: footerItemsProps[] = [
    {
      path: getLink(LINK_TYPE.LINKEDIN),
      text: "LinkedIn",
      icon: linkedin,
      target: "linkedin",
    },
    {
      path: getLink(LINK_TYPE.INSTAGRAM),
      text: "Instagram",
      icon: instagram,
      target: "instagram",
    },
    {
      path: getLink(LINK_TYPE.FACEBOOK),
      text: "Facebook",
      icon: facebook,
      target: "facebook",
    },
  ];

  const documents: footerItemsProps[] = [
    { path: getLink(LINK_TYPE.INNOVATIVE_STARTUP), text: t("footer.startup") },
    // { path: "Whitepaper", text: "Whitepaper" },
    { path: getLink(LINK_TYPE.TOS), text: t("footer.tos") },
    { path: getLink(LINK_TYPE.PRIVACY), text: t("footer.privacy") },
    { path: getLink(LINK_TYPE.COOCKIES), text: t("footer.cookies") },
  ];

  const hamburgerColor = pathname.includes(getLink(LINK_TYPE.PROFILE))
    ? "black"
    : "white";

  function closeMenu() {
    setIsOpen(false);
  }

  return (
    <div>
      <motion.div initial={false} animate={isOpen ? "open" : "closed"}>
        <div className="w-100 h-100">
          <div className={`menuCircle ${isOpen ? "menuCircleOpen" : ""}`}></div>
        </div>
        <div
          onClick={(e) => {
            if (!isOpen) {
              fireTagManagerEvent("open_menu");
            }

            setIsOpen(!isOpen);
            e.stopPropagation();
          }}
          className={`menuHamburger ${isOpen ? "menuHamburgerOpen" : ""}`}
        >
          <div className="p1" style={{ backgroundColor: hamburgerColor }} />
          <div className="p2" style={{ backgroundColor: hamburgerColor }} />
          <div className="p3" style={{ backgroundColor: hamburgerColor }} />
        </div>

        {/* {isOpen ? ( */}
        <motion.div
          variants={{
            open: { opacity: 1, pointerEvents: "all" },
            closed: { opacity: 0, pointerEvents: "none" },
          }}
          style={{
            top: 0,
            left: 0,
            zIndex: 100,
            overflow: "auto",
          }}
          className="position-absolute p-0 d-flex flex-column align-items-center justify-content-between gap-2 vw-100 vh-100"
        >
          <div className="d-flex flex-column w100 align-items-center">
            <div
              className="container mx-auto d-flex d-column align-items-center"
              // className="d-flex justify-content-between"
            >
              <div className="d-flex justify-content-between align-items-center w100 my-2 p-0 px-2">
                <div className="d-flex align-items-center gap-1 gap-md-4">
                  <div className="menuClose"></div>
                  <motion.img
                    variants={{
                      open: { opacity: 1, pointerEvents: "all" },
                      closed: { opacity: 0, pointerEvents: "none" },
                    }}
                    transition={{ duration: 1 }}
                    src={TakyonColor}
                    alt=""
                    style={{
                      ...(isSmallScreen
                        ? {
                            transform: "scale(0.8)",
                          }
                        : isShortScreen
                        ? {
                            transform: "scale(1.1)",
                          }
                        : {
                            width: "120px",
                          }),
                    }}
                  />
                </div>

                {!isHeroVisible &&
                !pathname.includes(getLink(LINK_TYPE.FORCLIENTS)) &&
                !pathname.includes(getLink(LINK_TYPE.FORHOTELS)) &&
                !pathname.includes(getLink(LINK_TYPE.FOREXPERIENCE)) &&
                !pathname.includes(getLink(LINK_TYPE.FORGENERIC)) &&
                !pathname.includes(getLink(LINK_TYPE.FORPARTNERS)) ? (
                  <div className="menuHeight"></div>
                ) : (
                  <div
                    style={{
                      width: "55px",
                      height: "24px",
                    }}
                  ></div>
                )}

                {/* <NavLang nav lang={lang} setLang={setLang} /> */}
                <motion.p
                  variants={{
                    open: {
                      x: 0,
                      opacity: 1,
                    },
                    closed: {
                      x: -100,
                      opacity: 0,
                    },
                  }}
                  transition={{ duration: 0.5 }}
                  onClick={() => {
                    fireTagManagerEvent("click_become_partner");
                    setIsOpen(false);
                    navigate(getLink(LINK_TYPE.FORPARTNERS));
                  }}
                  className={
                    "bodytext cursor-pointer text-nowrap text-decoration-underline"
                  }
                >
                  <>{t("nav.becomepartner")}</>
                </motion.p>
              </div>
            </div>

            <div className="d-flex flex-column w100 container">
              <hr className="mt-0" />
              <CategoriesNav callBack={closeMenu} isOpen={isOpen} />
              {links.map((link, index) => {
                return (
                  <p
                    key={index}
                    onClick={() => {
                      fireTagManagerEvent(`click_${link.link}`);
                      setIsOpen(false);
                      navigate(link.link);
                    }}
                    className={`bodytext-lg light cursor-pointer text-nowrap hover-underline py-2 ${
                      pathname === link.link
                        ? "text-decoration-underline"
                        : "dark-grey-color "
                    }`}
                  >
                    <>{link.title}</>
                  </p>
                );
              })}

              {user?.isAdmin ? (
                <>
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();

                      await AdminController.loginAs({
                        email: (e.target as any).email.value,
                      });
                    }}
                  >
                    <input name="email" type="text" />
                  </form>
                </>
              ) : null}

              <hr />

              {support.map((support, index) => (
                <div key={index}>
                  <a
                    href={support.path}
                    className="bodytext light dark-grey-color text-decoration-none"
                  >
                    {support.text}
                  </a>
                  <div className=""></div>
                </div>
              ))}
            </div>
          </div>

          <div className="w100" style={{ maxWidth: contentMaxWidth }}>
            <hr />
            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between gap-md-5 w100">
              <div className="d-flex align-items-center gap-4 me-5">
                {follow.map((follow, index) => (
                  <div key={index}>
                    <a
                      onClick={() => {
                        fireTagManagerEvent("link_social", {
                          target: follow.target,
                        });
                      }}
                      href={follow.path}
                      className="dark-grey-color"
                    >
                      <img src={follow.icon} alt="" />
                    </a>
                  </div>
                ))}
              </div>

              <div className="height-16"></div>

              <div className="d-flex flex-column flex-md-row justify-content-md-between w100">
                {documents.map((document, index) => (
                  <div key={index}>
                    <a
                      className="bodytext-xl light dark-grey-color text-decoration-none"
                      onClick={() => {
                        navigate(document.path);
                        setIsOpen(false);
                      }}
                    >
                      {document.text}
                    </a>
                    <div className="height-8 only-desktop"></div>
                  </div>
                ))}
              </div>
            </div>
            <div className="height-32"></div>
          </div>
        </motion.div>
        {/* ) : null} */}
      </motion.div>

      <div className="web-section-content"></div>
    </div>
  );
}

function NavLang({ nav, lang, setLang }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const changeLocaleLanguageSelect = (selectedLang: LANG) => {
    setLang?.(selectedLang);
    setLocaleLanguage(selectedLang);
    setIsOpen(false);
  };

  const initAsync = async () => {
    await getLocaleLanguage();
  };

  useEffect(() => {
    initAsync();
  }, []);

  return (
    <div className="position-relative">
      <Button
        style={{
          paddingRight: "15px",
          border: "unset !important",
          color: !pathname.includes(getLink(LINK_TYPE.PROFILE))
            ? "white"
            : "black",
          fill: !pathname.includes(getLink(LINK_TYPE.PROFILE))
            ? "white"
            : "black",
        }}
        size="sm"
        variant="blank"
        icon={isOpen ? "expand_less" : "expand_more"}
        text={lang?.toUpperCase()}
        onClick={(e) => {
          setIsOpen(!isOpen);
          e.stopPropagation();
        }}
      />
      {isOpen ? (
        <div
          style={{
            top: "120%",
            right: 0,
          }}
          className="position-absolute p-3 gap-2 white-background d-flex flex-column align-items-end rounded shadow"
        >
          {Object.keys(LANG).map((langKey, key) => {
            const langValue = LANG[langKey as keyof typeof LANG];
            return (
              <p
                onClick={() => {
                  changeLocaleLanguageSelect(langValue);
                  setIsOpen(false);
                }}
                className="m-0 bodytext light cursor-pointer text-nowrap hover-underline"
                key={key}
              >
                <>{langValue.toUpperCase()}</>
              </p>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

interface InnerNavProps {
  showActions?: boolean;
  light?: boolean;
}

export function CategoriesNav({
  callBack,
  isOpen,
}: {
  callBack: () => void;
  isOpen: boolean;
}) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const defaultStatusMenu = !pathname.includes(getLink(LINK_TYPE.FORCLIENTS));
  const [menuOpened, setMenuOpened] = useState(defaultStatusMenu);
  const navigate = useNavigate();
  const [categories, setCategories] = useState<iWallConfigObject[]>([]);

  useEffect(() => {
    const menuCategories: iWallConfig = WALL_CONFIG.config;
    setCategories(menuCategories);
  }, []);

  return (
    <motion.div animate={isOpen ? "open" : "closed"}>
      <div
        className={`d-flex align-items-center ${
          menuOpened ? "text-black" : "dark-grey-color light"
        }`}
      >
        <p
          className={`bodytext-lg regular cursor-pointer hover-underline text-nowrap user-select-none py-2 ${
            menuOpened ? "text-decoration-underline" : ""
          }`}
          onClick={() => {
            setMenuOpened(!menuOpened);
          }}
        >
          {t("nav.enter")}
        </p>
        <Icon
          icon={menuOpened ? "keyboard_arrow_down" : "keyboard_arrow_right"}
        ></Icon>
      </div>
      <motion.ul
        variants={{
          open: {
            transition: { staggerChildren: 0.07, delayChildren: 0.2 },
          },
          closed: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 },
          },
        }}
        className="p-0 gap-2 d-grid d-lg-flex flex-lg-row menuCatContainer"
      >
        {categories && categories.length > 0 && menuOpened
          ? categories.map((category: iWallConfigObject, index: number) => {
              let backgroundImage = all;
              switch (category.area) {
                case "stay-city":
                  backgroundImage = staycity;
                  break;
                case "experience":
                  backgroundImage = stayexperience;
                  break;
                case "stay-lake":
                  backgroundImage = staylake;
                  break;
                case "stay-mountain":
                  backgroundImage = staymountain;
                  break;
                case "stay-sea":
                  backgroundImage = staysea;
                  break;
                case "stay-trips":
                  backgroundImage = staytrips;
                  break;
              }
              return (
                <motion.li
                  variants={{
                    open: {
                      opacity: 1,
                      y: 0,
                    },
                    closed: {
                      opacity: 0,
                      y: -30,
                    },
                  }}
                  key={index}
                  className="rounded-4 p-2 mb-3 d-flex flex-column justify-content-end menuCatTile"
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                  }}
                  onClick={() => {
                    callBack();
                    const area =
                      category.area === "all" ? "/" : `/${category.area}`;
                    navigate(area);
                  }}
                >
                  <div className="velina rounded-4"></div>
                  <p
                    className="bodytext-sm regular text-black user-select-none py-1 px-2 white-background rounded text-ellipsis"
                    style={{ maxWidth: "140px", zIndex: 1 }}
                    title={t(category.categoryName)}
                  >
                    {t(category.categoryName)}
                  </p>
                </motion.li>
              );
            })
          : null}
      </motion.ul>
    </motion.div>
  );
}

export function InnerNav({ showActions = true }: InnerNavProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const [lang, setLang] = useState<LANG | undefined>();
  const isHeroVisible = useSelector(selectHeroVisibility);
  const { pathname } = useLocation();
  const offersPendingCount = useSelector(selectSellerPendingOffersCount);
  const isProfileMenuOpened = useSelector(selectProfileMenuVisibility);
  const isShortScreen = useMediaQuery({
    query: "only screen and (min-width : 769px) and (max-height : 600px)",
  });
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initAsync = async () => {
      const l = await getLocaleLanguage();

      const langValue: LANG | undefined = l as LANG | undefined;
      setLang(langValue);
    };
    initAsync();
  }, []);

  return (
    <div className="container mx-auto">
      <div className="content">
        <div
          className={`d-flex justify-content-between align-items-center w100 p-0 px-2 rounded ${
            !isShortScreen ? "my-2" : ""
          }`}
        >
          <div className="d-flex align-items-center gap-1 gap-md-4">
            <div>
              {(isSmallScreen && !isProfileMenuOpened) || !isSmallScreen ? (
                <NavMenu lang={lang} setLang={setLang} />
              ) : null}
            </div>
            <div
              className="cursor-pointer white-color"
              onClick={() => {
                navigate(getLink(LINK_TYPE.WALL));
                dispatch(setProfileMenu(false));
              }}
            >
              <img
                className="menuLogo"
                src={TakyonLight}
                alt=""
                style={{
                  ...(isSmallScreen
                    ? {
                        transform: "scale(0.8)",
                      }
                    : isShortScreen
                    ? {
                        transform: "scale(1.1)",
                      }
                    : {
                        width: "120px",
                      }),

                  filter: pathname.includes(getLink(LINK_TYPE.PROFILE))
                    ? "invert(1)"
                    : "none",
                }}
              />
            </div>
          </div>

          <div className="d-flex align-items-center gap-3">
            {(isSmallScreen && !isProfileMenuOpened) || !isSmallScreen ? (
              <>
                <NavLang lang={lang} setLang={setLang} />

                {showActions && !isHeroVisible ? (
                  <>
                    {!pathname.includes(getLink(LINK_TYPE.PROFILE)) &&
                      !pathname.includes(getLink(LINK_TYPE.FORCLIENTS)) &&
                      !pathname.includes(getLink(LINK_TYPE.FORHOTELS)) &&
                      !pathname.includes(getLink(LINK_TYPE.FOREXPERIENCE)) &&
                      !pathname.includes(getLink(LINK_TYPE.FORGENERIC)) &&
                      !pathname.includes(getLink(LINK_TYPE.FORPARTNERS)) && (
                        <Button
                          size="sm"
                          onClick={() => {
                            fireTagManagerEvent("open_search");
                            openSearchModal();
                          }}
                          style={{ backgroundColor: "white" }}
                          icon={"search"}
                          text={!isSmallScreen ? t("nav.find") : ""}
                        />
                      )}

                    {!pathname.includes(getLink(LINK_TYPE.FORCLIENTS)) &&
                    !pathname.includes(getLink(LINK_TYPE.FORHOTELS)) &&
                    !pathname.includes(getLink(LINK_TYPE.FOREXPERIENCE)) &&
                    !pathname.includes(getLink(LINK_TYPE.FORGENERIC)) &&
                    !pathname.includes(getLink(LINK_TYPE.FORPARTNERS)) ? (
                      <Button
                        size="sm"
                        onClick={() => {
                          dispatch(setProfileMenu(true));
                          if (
                            user &&
                            user.emailVerified &&
                            typeof user.marketingEmails !== "undefined" &&
                            user.firstName &&
                            user.secondName &&
                            user.phone &&
                            user.phonePrefix &&
                            user.dateOfBirth
                          ) {
                            navigate(getLink(LINK_TYPE.PROFILE_WALLET));
                          } else openAuthModal();
                        }}
                        iconFill
                        icon={"person"}
                        notification={
                          offersPendingCount > 0
                            ? offersPendingCount
                            : undefined
                        }
                        text={
                          isSmallScreen
                            ? user
                              ? String(
                                  user.firstName?.[0] ?? ""
                                ).toUpperCase() +
                                String(user.secondName?.[0] ?? "").toUpperCase()
                              : ""
                            : user
                            ? (user.firstName ?? "") +
                              " " +
                              (user.secondName ?? "")
                            : t("nav.login")
                        }
                      />
                    ) : null}

                    {pathname.includes(getLink(LINK_TYPE.FORCLIENTS)) ||
                    pathname.includes(getLink(LINK_TYPE.FORHOTELS)) ||
                    pathname.includes(getLink(LINK_TYPE.FOREXPERIENCE)) ||
                    pathname.includes(getLink(LINK_TYPE.FORGENERIC)) ||
                    pathname.includes(getLink(LINK_TYPE.FORPARTNERS)) ? (
                      <Button
                        size={isSmallScreen ? "sm" : undefined}
                        text={t("forpartners.section_1.button")}
                        iconFill
                        onClick={() => {
                          scrollTo("becomePartnerForm");
                        }}
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Icon
                  className="black-color"
                  icon="close"
                  onClick={() => dispatch(setProfileMenu(false))}
                ></Icon>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Nav() {
  const isHeroVisible = useSelector(selectHeroVisibility);
  const { pathname } = useLocation();

  return (
    <nav
      style={{
        zIndex: 200,
        borderBottom: "1px solid",
        borderColor: !pathname.includes(getLink(LINK_TYPE.PROFILE))
          ? "rgba(255, 255, 255, 0.5)"
          : "#dee2e6",
        top: 0,
      }}
      className={`position-fixed w100 py-1 ${
        !isHeroVisible
          ? !pathname.includes(getLink(LINK_TYPE.PROFILE))
            ? "black-background"
            : "white-background"
          : ""
      }`}
    >
      <InnerNav />
    </nav>
  );
}
