import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OfferController from "../../../../assets/Controllers/OfferController";
import { DEFAULT_PAGINATION_SIZE } from "../../../../assets/models/iPagination";
import { BID_STATUS, iBid } from "../../../../assets/models/iBid";
import { apiErrorToast } from "../../../../assets/Utils/errors";
import TakDealBuy from "../../../../assets/TakyonDesignSystem/components/TakDeal/TakDealBuy";
import helpers from "../../../../_Web/helpers/helpers";
import TakCard from "../../../../assets/TakyonDesignSystem/components/TakCard/TakCard";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../Utils/generic";
import TakOnSaleHeader from "../TakDeal/TakOnSaleHeader";
// import { useSelector } from "react-redux";
// import { selectPendingOrders } from "../../../../redux/slices/orderSlice";

export default function OfferList({
  side,
  expired = false,
}: {
  side: "buyer" | "seller";
  expired?: boolean;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [nftOffersMap, setNftOffersMap] = useState<Record<string, iBid[]>>({});

  const [page] = useState(1);
  const [size] = useState(DEFAULT_PAGINATION_SIZE);

  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  // const orders = useSelector(selectPendingOrders);

  const loadOffers = async () => {
    setIsLoading(true);
    try {
      let params =
        expired === true
          ? {
              page,
              size,
              query: JSON.stringify({
                expireDate: { $lt: { $date: helpers.getXDaysAgo(10) } },
              }),
            }
          : { page, size };

      const offers = await OfferController.getPaginated(params, side);

      setNftOffersMap((prevMap) => {
        const updatedMap: Record<string, iBid[]> = { ...prevMap };

        offers.data.forEach((offer) => {
          const nftId = offer._nft?._id || "unknown";

          updatedMap[nftId] = [...(updatedMap[nftId] || []), offer];
        });

        return updatedMap;
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  // sort offers
  const filterAndSortOffers = (offers: any) => {
    const activeOffers = offers.filter(
      (offer: any) =>
        offer.status === BID_STATUS.PENDING ||
        offer.status === BID_STATUS.BUYER_COUNTEROFFER ||
        offer.status === BID_STATUS.SELLER_COUNTEROFFER ||
        offer.status === BID_STATUS.ACCEPTED
    );

    return {
      activeOffers,
      pendingOffersCount: activeOffers.length,
    };
  };

  const sortedNftOffers = Object.entries(nftOffersMap).sort(
    ([, offersA], [, offersB]) => {
      const { activeOffers: activeOffersA } = filterAndSortOffers(offersA);
      const { activeOffers: activeOffersB } = filterAndSortOffers(offersB);

      return activeOffersB.length - activeOffersA.length;
    }
  );

  useEffect(() => {
    loadOffers();
  }, []);

  if (isLoading) return <span>Loading ...</span>;

  return sortedNftOffers.length === 0 ? (
    <p>
      {t(side == "buyer" ? "offers.done.nooffers" : "offers.received.nooffers")}
    </p>
  ) : (
    <div className="d-flex flex-column">
      {sortedNftOffers.map(([nftId, offers], index) => {
        const nft = offers[0]._nft!;
        const ownerName = offers[0]._ownerName!;

        return (
          <>
            {index > 0 && (
              <div
                className="border-top p-0 mt-4"
                style={{ height: "32px" }}
              ></div>
            )}
            <div
              className="d-flex flex-column flex-md-row gap-3"
              key={nft?._id}
            >
              <div style={{ width: isSmallScreen ? "100%" : "250px" }}>
                <TakCard
                  style={{ width: "inherit" }}
                  preventAspectRatio={isSmallScreen}
                  nft={nft}
                  small={true}
                />
              </div>
              <div className="w-100">
                {side === "buyer" ? (
                  <div>
                    <p className="bodytext light">
                      {t("wall.owner")} {ownerName}
                    </p>
                    <p className="h4">
                      {t("wallet.generic.forsale")}{" "}
                      {helpers.price(nft?.currentPrice!)}
                    </p>
                  </div>
                ) : (
                  <TakOnSaleHeader nft={nft} />
                )}
                <hr />
                <TakDealBuy bids={offers} />
              </div>
              {index < sortedNftOffers.length - 1 && (
                <div
                  className="border-bottom p-0"
                  style={{ height: "32px" }}
                ></div>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
}
