import { useTranslation } from "react-i18next";
import helpers from "../../../../_Web/helpers/helpers";
import Button from "../Button/Button";
import { iNft, NFT_SELL_STATUS } from "../../../models/iNft";
import { openModal } from "../../../Utils/modal";
import TakCreditRefund from "../../../../_Web/components/Profile/Wallet/Actions/TakCreditRefund";

export default function TakOnSaleHeader({ nft }: { nft: iNft }) {
  const { t } = useTranslation();

  const isOnSale = nft.sellStatus !== NFT_SELL_STATUS.NOT_FOR_SALE;

  return (
    <div className="d-flex flex-column flex-xl-row">
      <div className="w-100 d-flex flex-column gap-2">
        <p className="bodytext">
          {t("wallet.generic.originallypurchased")}{" "}
          {helpers.price(nft.originalPrice!)}
        </p>
        <p className="h4">
          {t("wallet.generic.forsale")} {helpers.price(nft.currentPrice!)}
        </p>
      </div>
      <div>
        {isOnSale && (
          <Button
            className="w-100 mt-3 mt-xl-0"
            onClick={() => {
              openModal({
                content: <TakCreditRefund nft={nft} />,
              });
            }}
            text={t("wallet.credits.obtainbutton")}
          />
        )}
      </div>
    </div>
  );
}
