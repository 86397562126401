import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import Input from "../../../../../assets/TakyonDesignSystem/components/Input/Input";
import { iNft } from "../../../../../assets/models/iNft";
import { DEFAULT_FEE_SPLIT } from "../../../../../config/Fees";
import { Form } from "react-bootstrap";
import { closeModal, openModal } from "../../../../../assets/Utils/modal";
import NftController from "../../../../../assets/Controllers/NftController";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import { fireTagManagerEvent } from "../../../../../assets/Services/tagmanager";
import _ProfileSectionLayout from "../../_ProfileSectionLayout";
import OfferDetails from "../../../../../assets/TakyonDesignSystem/components/TakDeal/OfferDetails";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import helpers from "../../../../helpers/helpers";
import TakSaleDetails from "../../../../../assets/TakyonDesignSystem/components/TakDeal/TakSaleDetails";

export default function TakChangePrice() {
  // { nft }: { nft: iNft }
  let location = useLocation();
  const navigate = useNavigate();

  const nft: iNft = location.state;
  const [price, setPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const receivedPrice = useMemo(() => {
    return (Number(price) * DEFAULT_FEE_SPLIT.seller).toFixed(2);
  }, [price, DEFAULT_FEE_SPLIT.seller]);

  const serviceFee = useMemo(() => {
    return ((1 - DEFAULT_FEE_SPLIT.seller) * 100).toFixed(0);
  }, [DEFAULT_FEE_SPLIT.seller]);

  const changePrice = async () => {
    setIsLoading(true);
    try {
      const prevAmount = nft.currentPrice;

      await NftController.changeNftPrice({
        nftId: nft._id,
        price: Number(price),
      });

      fireTagManagerEvent("change_price");

      openModal(
        {
          content: (
            <ActionFeedbackModal
              icon="check_circle"
              title={t("wallet.bookings.changepricesuccesstext")}
              continueClickCallback={() => navigate("/profile/wallet")}
              continueBtnText={t("wallet.bookings.viewtak")}
            />
          ),
        },
        true
      );
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_ProfileSectionLayout nft={nft} title={t("wallet.bookings.changeprice")}>
      <span className="h4 medium">
        {t("wallet.bookings.forsale")}: {helpers.price(nft.currentPrice!)}
      </span>

      <div className="height-24"></div>

      <div className="d-flex gap-2">
        <Form.Group className="w100">
          <Form.Label>{t("wallet.bookings.newprice")}</Form.Label>
          <Input
            status={Number(price) > 0 ? "success" : "normal"}
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder={t("wallet.bookings.newpriceph")}
          />
        </Form.Group>
      </div>

      <TakSaleDetails amount={Number(price)} />

      <hr className="mt-0" />

      <div className="d-flex justify-content-end">
        <Button
          disabled={!price}
          onClick={changePrice}
          loading={isLoading}
          text={t("wallet.bookings.changeprice")}
        />
      </div>
    </_ProfileSectionLayout>
  );
}
