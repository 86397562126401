import { useEffect, useState } from "react";
import {
  NFT_SELL_STATUS,
  iNft,
  payloadExperienceType,
  payloadHotelType,
  poi,
  review,
  reviewSummary,
  room,
  service,
} from "../../../assets/models/iNft";
import NftController from "../../../assets/Controllers/NftController";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import {
  getAmountOfGuests,
  getCityFromLocation,
  getCityShortFromLocation,
  getCountryFromLocation,
  getRegionFromLocation,
} from "../../../assets/Utils/location";
import Stars from "../../../assets/TakyonDesignSystem/components/Stars/Stars";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import {
  SMALL_SCREEN,
  calculateAmountOfCommonElementInArray,
  calculateCommonWordPercentage,
  datetimeRangeToString,
  datetimeToString,
  formatMinutes,
  getAmountOfNights,
} from "../../../assets/Utils/generic";
import { DEFAULT_LANGUAGE } from "../../../config/Lang";
import { InnerNav } from "../../components/Nav/Nav";
import isHtml from "is-html";
import { useMediaQuery } from "react-responsive";
import NftMap from "../../../assets/TakyonDesignSystem/components/NftCard/sub/NftMap";
import getLink, { LINK_TYPE } from "../../../config/Links";
import openBuyModal from "../../components/Checkout/Buy";
import { useSelector } from "react-redux";
import { isFavorite, selectUser } from "../../../redux/slices/userSlice";
import { openModal } from "../../../assets/Utils/modal";
import Share from "../../../assets/TakyonDesignSystem/components/Share/Share";
import openOfferModal from "../../components/Checkout/Offer";
import { TakCarousel } from "../../../assets/TakyonDesignSystem/components/TakContainer/TakContainer";
import Footer from "../../components/Footer/Footer";
import openAuthModal from "../../components/Auth/Auth";
import Favorites from "../../components/Modals/Favorites";
import { RootState } from "../../../redux/redux";
import FavoritesController from "../../../assets/Controllers/FavoritesController";
import { COLLECTION_TYPE } from "../../../assets/models/iCollection";
import CollectionController from "../../../assets/Controllers/CollectionController";
import { getTranslationExperience } from "../../../assets/Utils/langutils";
import helpers from "../../helpers/helpers";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";

export function openNftDetailPage(nftId: string) {
  // Create a link element
  const link = document.createElement("a");

  // Set the URL, dynamically replacing the NFT ID placeholder
  link.href = getLink(LINK_TYPE.TAK, "EXCHANGE_FULL", { __NFTID__: nftId });

  // For smaller screens or specific conditions, open in a new tab
  if (window.innerWidth > 576) {
    link.target = "_blank";
    link.rel = "noopener noreferrer";
  }

  // Append to the body to ensure visibility in the DOM (important for some browsers)
  document.body.appendChild(link);

  // Trigger the click
  link.click();

  // Remove the link after click to clean up the DOM
  document.body.removeChild(link);
}

interface ChevronButtonProps {
  direction: "left" | "right";
  onClick: Function;
}

function ChevronButton({ direction, onClick }: ChevronButtonProps) {
  return (
    <div
      className="height-40 width-40 d-flex align-items-center justify-content-center m-3 cursor-pointer"
      style={{
        border: "1px solid white",
        borderRadius: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      }}
      onClick={() => onClick()}
    >
      <Icon className="white-color" icon={`chevron_${direction}`} size={30} />
    </div>
  );
}

interface RoomImagesProps {
  images: string[];
}

function RoomImages({ images }: RoomImagesProps) {
  const [index, setIndex] = useState(0);

  return (
    <div className="w100 h100 d-flex flex-column gap-2 position-relative">
      <img
        className=" w100"
        style={{ objectFit: "cover", height: "80%" }}
        src={images[index]}
        alt=""
      />

      {images.length > 1 ? (
        <>
          <div
            style={{ top: "40%" }}
            className="d-flex justify-content-between position-absolute w100 "
          >
            <ChevronButton
              direction="left"
              onClick={() => {
                setIndex(Math.max(0, index - 1));
              }}
            />
            <ChevronButton
              direction="right"
              onClick={() => {
                setIndex(Math.min(images.length - 1, index + 1));
              }}
            />
          </div>
        </>
      ) : null}

      <div style={{ height: "20%" }} className="d-flex flex-wrap gap-2">
        {images.map((img, key) => {
          return (
            <img
              className="cursor-pointer"
              onClick={() => setIndex(key)}
              key={key}
              style={{ objectFit: "cover", height: "100px", width: "100px" }}
              src={img}
              alt=""
            />
          );
        })}
      </div>
    </div>
  );
}

export default function TakPage() {
  const { id } = useParams();
  const { i18n, t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const navigate = useNavigate();

  const [nft, setNft] = useState<iNft>();
  const [isLoading, setIsLoading] = useState(false);
  const [isActionFixed, setIsActionFixed] = useState(false);

  const [rooms, setRooms] = useState<room[]>([]);
  const [services, setServices] = useState<service[]>([]);
  const [poi, setPoi] = useState<poi[]>([]);
  const [reviewSummary, setReviewSummary] = useState<reviewSummary>();
  const [reviews, setReviews] = useState<review[]>([]);
  const [description, setDescription] = useState<string>("");
  const [images, setImages] = useState<string[]>([]);

  const [expDescription, setExpDescription] = useState<string>("");
  const [hostName, setHostName] = useState<string>("");
  const [hostDescription, setHostDescription] = useState<string>("");
  const [expName, setExpName] = useState<string>("");

  const [type, setType] = useState<COLLECTION_TYPE>();

  const [keys, setKeys] = useState<string[]>([]);

  const user = useSelector(selectUser);
  const [liked, setLiked] = useState(false);

  const isLiked = useSelector((state: RootState) =>
    isFavorite(state, String(id))
  );

  useEffect(() => {
    setLiked(isLiked);
  }, [isLiked]);

  const [imgIndex, setImgIndex] = useState(0);

  const [relatedNfts, setRelatedNfts] = useState<iNft[]>([]);

  const [board, setBoard] = useState<"RO" | "BNB" | "HB" | "FB" | "AI">("BNB");

  const boards: any = {
    RO: "app.nft.ro",
    BNB: "app.nft.bnb",
    HB: "app.nft.hb",
    FB: "app.nft.fb",
    AI: "app.nft.ai",
  };

  const loadRelatedNfts = async () => {
    try {
      const data = await NftController.getPaginated({
        page: 1,
        size: 10,
        query: JSON.stringify({ sellStatus: NFT_SELL_STATUS.FOR_SALE }),
      });

      setRelatedNfts(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let timerScroll = window.setTimeout(() => {
      window.removeEventListener("scroll", handleScroll);
    }, 5000);
    const handleScroll = () => {
      window.removeEventListener("scroll", handleScroll);
      fireTagManagerEvent("scroll_card");
      clearTimeout(timerScroll);
    };

    window.addEventListener("scroll", handleScroll);

    loadRelatedNfts();
  }, []);

  useEffect(() => {
    let keys = poi?.map((p) => p.icon);
    keys = keys?.filter(function (item, pos) {
      return keys.indexOf(item) === pos;
    });

    setKeys(keys);
  }, [nft, poi]);

  useEffect(() => {
    setType(nft?.payload?.type);
  }, [nft]);

  const load = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getNft(String(id)); // TODO get from short ID
      setNft(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [id]);

  useEffect(() => {
    setRooms([]);
    setServices([]);
    setPoi([]);
    setReviewSummary(undefined);
    setReviews([]);
    setDescription("");
  }, [id]);

  useEffect(() => {
    if (nft?.payload?.board) setBoard(nft?.payload?.board);
  }, [nft]);

  useEffect(() => {
    const check = () => {
      const actionBarEl = document.getElementById("nft-page-action-bar");
      if (!actionBarEl) return;

      const actionBarFixed =
        window.scrollY - window.innerHeight + actionBarEl.offsetHeight > 0;

      setIsActionFixed(actionBarFixed);
    };

    document.addEventListener("scroll", check);
  }, [nft]);

  useEffect(() => {
    if (!nft || !nft.payload?.category) return;
    if (type !== COLLECTION_TYPE.experience) return;

    const exp = getTranslationExperience(nft, i18n);
    if (!exp) return;

    setExpDescription(exp.description);
    setHostName(exp.hostName);
    setHostDescription(exp.hostDescription);
    setExpName(exp.name);
  }, [nft, type, i18n.language]);

  useEffect(() => {
    if (!nft || !nft.payload?.rooms) return;
    if (type && type !== COLLECTION_TYPE.hotel) return;

    (async () => {
      try {
        const myRooms: room[] = [];

        for (const payloadRoom of nft.payload?.rooms!) {
          let bestIndex = -1;
          let bestPercentage = 0;
          let bestAmount = 0;

          const availLangs = Object.keys(nft._collectionCensus?.info!);

          for (const lang of availLangs) {
            const collectionInfoLang =
              (nft._collectionCensus?.info as any)[lang]?.rooms || [];

            for (
              let roomIndex = 0;
              roomIndex < collectionInfoLang.length;
              roomIndex++
            ) {
              const roomInfo: room = collectionInfoLang[roomIndex];

              const percentage = calculateCommonWordPercentage(
                roomInfo.name,
                payloadRoom!.name!
              );

              const amount = calculateAmountOfCommonElementInArray(
                roomInfo.name.split(" "),
                payloadRoom!.name!.split(" ")
              );

              if (amount > bestAmount) {
                bestAmount = amount;
                bestIndex = roomIndex;
              } else if (amount === bestAmount && percentage > bestPercentage) {
                bestPercentage = percentage;
                bestIndex = roomIndex;
              }
            }
          }

          let room = (nft._collectionCensus?.info as any)[i18n.language]
            ?.rooms?.[bestIndex];

          if (!room) {
            room = (nft._collectionCensus?.info as any).en?.rooms?.[bestIndex];
          }

          if (room) {
            myRooms.push(room);
          }
        }

        setRooms(myRooms);
      } catch (error) {
        console.log("Error getting rooms", error);
      }
    })();
  }, [nft, type, i18n.language]);

  useEffect(() => {
    if (!nft || !nft._collectionCensus) return;
    if (type && type !== COLLECTION_TYPE.hotel) return;

    try {
      let services;

      if (
        nft._collectionCensus.info &&
        (nft._collectionCensus?.info as any)[i18n.language]
      ) {
        services = (nft._collectionCensus?.info as any)[i18n.language].services;
      } else {
        services = (nft._collectionCensus.info as any)[DEFAULT_LANGUAGE]
          .services;
      }

      setServices(services);
    } catch (error) {
      console.log("Error getting services", error);
    }
  }, [nft, type, i18n.language]);

  useEffect(() => {
    if (!nft || !nft._collectionCensus) return;
    if (type && type !== COLLECTION_TYPE.hotel) return;

    try {
      let reviews;

      if (
        nft._collectionCensus.info &&
        (nft._collectionCensus?.info as any)[i18n.language]
      ) {
        reviews = (nft._collectionCensus?.info as any)[i18n.language].reviews;
      } else {
        reviews = (nft._collectionCensus.info as any)[DEFAULT_LANGUAGE].reviews;
      }

      setReviews(reviews);
    } catch (error) {
      // console.log("Error getting reviews", error);
    }
  }, [nft, type, i18n.language]);

  useEffect(() => {
    if (!nft || !nft._collectionCensus) return;
    if (type && type !== COLLECTION_TYPE.hotel) return;

    try {
      let poi;

      if (
        nft._collectionCensus.info &&
        (nft._collectionCensus?.info as any)[i18n.language]
      ) {
        poi = (nft._collectionCensus?.info as any)[i18n.language].poi;
      } else {
        poi = (nft._collectionCensus.info as any)[DEFAULT_LANGUAGE].poi;
      }

      setPoi(poi);
    } catch (error) {
      console.log("Error getting poi", error);
    }
  }, [nft, type, i18n.language]);

  useEffect(() => {
    if (!nft || !nft._collectionCensus) return;
    if (type && type !== COLLECTION_TYPE.hotel) return;

    try {
      let reviewSummary;

      if (
        nft._collectionCensus.info &&
        (nft._collectionCensus?.info as any)[i18n.language]
      ) {
        reviewSummary = (nft._collectionCensus?.info as any)[i18n.language]
          .reviewSummary;
      } else {
        reviewSummary = (nft._collectionCensus.info as any)[DEFAULT_LANGUAGE]
          .reviewSummary;
      }

      setReviewSummary(reviewSummary);
    } catch (error) {
      console.log("Error getting reviewSummary", error);
    }
  }, [nft, type, i18n.language]);

  useEffect(() => {
    if (!nft || !nft._collectionCensus) return;
    if (type && type !== COLLECTION_TYPE.hotel) return;

    try {
      let description;

      if (
        nft._collectionCensus.info &&
        (nft._collectionCensus?.info as any)[i18n.language]
      ) {
        description = (nft._collectionCensus?.info as any)[i18n.language]
          .description;
      } else {
        description = (nft._collectionCensus.info as any)[DEFAULT_LANGUAGE]
          .description;
      }

      setDescription(description);
    } catch (error) {
      console.log("Error getting description", error);
    }
  }, [nft, type, i18n.language]);

  useEffect(() => {
    if (nft) {
      let carouselImages = [...(nft._collectionImages ?? nft.images ?? [])];
      let firstImage: string;
      if (nft._collectionImages && nft.images?.length) {
        firstImage = nft.images[0];
        carouselImages.forEach(function (image, i) {
          if (image === firstImage) {
            carouselImages.splice(i, 1);
            carouselImages.unshift(image);
          }
        });
        if (!carouselImages.find((image) => image === firstImage)) {
          carouselImages.unshift(firstImage);
        }
      }
      setImages(carouselImages);
    }
  }, [nft, type]);

  const removeLike = async (userId: string, nftId: string) => {
    await FavoritesController.removeLike(userId, nftId);
  };

  const favoriteAction = async () => {
    if (!user) return openAuthModal();

    if (!liked) {
      // add like
      openModal({
        content: <Favorites nftId={String(nft?._id)} userId={user._id} />,
      });
    } else {
      setLiked(false);
      setIsLoading(true);
      try {
        await removeLike(user._id, String(nft?._id));
      } catch (error) {
        // rollback
        setTimeout(() => setLiked(true), 500);
      }
      setIsLoading(false);
    }
  };

  const shareAction = async () => {
    openModal({
      content: (
        <Share
          id={String(nft?._id)}
          link={getLink(LINK_TYPE.APP_EXPLORE_NFT, "API", {
            __NFTID__: String(nft?._id),
          })}
        />
      ),
    });
  };
  const offerAction = async () => {
    if (!user) return openAuthModal();
    fireTagManagerEvent("open_offer");
    openOfferModal(String(nft?._id));
  };
  const buyAction = async () => {
    if (!user) return openAuthModal();
    fireTagManagerEvent("open_buy");
    openBuyModal(String(nft?._id));
  };

  if (isLoading)
    return (
      <div
        className="black-background"
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon
          icon="progress_activity"
          type="symbol"
          className="white-color rotate-infinite"
          size={60}
        />
      </div>
    );

  if (!nft) {
    navigate(getLink(LINK_TYPE.WALL));
    return <></>;
  }

  let oldPrice = helpers.getOldPrice(nft);
  let currentPrice = helpers.getCurrentPrice(nft);

  const actionBar = (
    <div className="container">
      <div className="content">
        <div
          className="d-flex w100 justify-content-between align-items-center"
          style={{ padding: isSmallScreen ? "15px 0" : "24px 0" }}
        >
          {/* share like */}
          <div
            className="d-flex only-desktop"
            style={{ gap: "20px", marginTop: "auto" }}
          >
            <div
              onClick={favoriteAction}
              className="d-flex white-color align-items-center gap-2 light cursor-pointer"
            >
              <Icon icon="favorite" fill={false} type="symbol" />
              <span className="underline bodytext">
                {t("nftpage.add_favorite")}
              </span>
            </div>
            <div
              onClick={shareAction}
              className="d-flex white-color align-items-center gap-2 light cursor-pointer"
            >
              <Icon icon="ios_share" fill={false} />
              <span className="underline bodytext">{t("nftpage.share")}</span>
            </div>
          </div>
          {/* price & buttons */}
          <div
            className="d-flex align-items-baseline justify-content-between"
            style={{ gap: "20px", width: isSmallScreen ? "100%" : "" }}
          >
            <div
              className="d-flex flex-column flex-md-row gap-1 align-items-baseline"
              style={{ marginTop: "auto" }}
            >
              {oldPrice && oldPrice > currentPrice ? (
                <>
                  <span
                    style={{
                      textDecoration: "line-through",
                      lineHeight: isSmallScreen ? "10px" : "",
                    }}
                    className="dark-grey-color light m-0"
                  >
                    {`€${oldPrice}`}
                  </span>
                </>
              ) : null}
              <span className="white-color h3 m-0">€{currentPrice}</span>
            </div>

            {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE &&
            (!user || user?.email !== nft.owner) ? (
              <>
                <div
                  className="d-flex flex-column flex-md-row gap-2"
                  style={{ marginTop: "auto" }}
                >
                  <Button
                    size={isSmallScreen ? "sm" : undefined}
                    onClick={buyAction}
                    variant="light"
                    text={t("nftpage.buy")}
                  />
                  <Button
                    size={isSmallScreen ? "sm" : undefined}
                    onClick={offerAction}
                    text={t("nftpage.offer")}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );

  const handleButtonClick = (targetUrl: string) => {
    window.open(targetUrl, "_blank", "noopener,noreferrer");
  };

  const _link =
    nft.payload?.website !== undefined
      ? nft.payload?.website
      : nft._collectionCensus?.website;

  const link = _link
    ? _link.includes("http")
      ? _link
      : "https://" + _link
    : "";

  return (
    <div style={{ overflowY: "hidden" }} className="w100 black-background">
      {isActionFixed ? (
        <div
          className="black-background"
          style={{
            top: 0,
            position: "fixed",
            width: "100vw",
            zIndex: 100,
          }}
        >
          {actionBar}
        </div>
      ) : null}

      <div
        className="d-flex flex-column"
        style={{ width: "100vw", height: "100dvh" }}
      >
        <div style={{ position: "relative" }} className="h100 w100">
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <img
              style={{
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                zIndex: 1,
              }}
              src={images[imgIndex]}
              alt=""
            />
          </div>

          <div
            className="w100 d-flex justify-content-center"
            style={{ top: 0, position: "absolute", zIndex: 4 }}
          >
            <InnerNav showActions={false} />
          </div>

          <div
            style={{ top: "45%", zIndex: 3 }}
            className="d-flex justify-content-between position-absolute w100"
          >
            <ChevronButton
              direction="left"
              onClick={() => {
                setImgIndex(Math.max(0, imgIndex - 1));
              }}
            />
            <ChevronButton
              direction="right"
              onClick={() => {
                setImgIndex(Math.min(images.length - 1, imgIndex + 1));
              }}
            />
          </div>

          {imgIndex === 0 ? (
            <>
              <div
                className="h100 w100"
                style={{
                  top: 0,
                  position: "absolute",
                  zIndex: 2,
                  background:
                    "linear-gradient(180deg, rgba(23, 23, 23, 0.50) 0%, rgba(23, 23, 23, 0.90) 59.81%)",
                }}
              ></div>

              <div
                style={{
                  top: 0,
                  position: "absolute",
                  zIndex: 3,
                  padding: "34px",
                  marginTop: "30px",
                }}
                className="only-mobile d-flex gap-3 w100 justify-content-between"
              >
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                  size="sm"
                  icon="chevron_left"
                  variant="light"
                />
                <div className="d-flex gap-3">
                  <Button
                    onClick={favoriteAction}
                    size="sm"
                    icon="favorite"
                    variant="light"
                  />
                  <Button
                    onClick={shareAction}
                    size="sm"
                    icon="ios_share"
                    variant="light"
                  />
                </div>
              </div>

              <div
                className="h100 w100"
                style={{
                  top: 0,
                  zIndex: 3,
                  position: "absolute",
                  pointerEvents: "none",
                }}
              >
                <div className="container h100">
                  <div className="content h100">
                    <div
                      style={{
                        padding: isSmallScreen
                          ? "124px 24px 24px 24px"
                          : "50px",
                      }}
                      className="d-flex flex-column justify-content-center h100"
                    >
                      {type === COLLECTION_TYPE.hotel || !type ? (
                        <>
                          {nft._collectionCensus?.stars ? (
                            <Stars stars={nft._collectionCensus?.stars} />
                          ) : null}
                        </>
                      ) : null}

                      <span className="h1-large regular white-color">
                        {type === COLLECTION_TYPE.hotel || !type ? (
                          <>{nft._collectionCensus?.name}</>
                        ) : null}

                        {type === COLLECTION_TYPE.experience ? (
                          <>{expName ?? nft.payload?.category}</>
                        ) : null}
                      </span>

                      <div className="height-20"></div>

                      {type === COLLECTION_TYPE.hotel || !type ? (
                        <>
                          <span className="h4 regular white-color">
                            {getCityFromLocation(
                              nft._collectionCensus?.location
                            )}{" "}
                            (
                            {getCityShortFromLocation(
                              nft._collectionCensus?.location
                            )}
                            ,{" "}
                            {getRegionFromLocation(
                              nft._collectionCensus?.location
                            )}
                            ,{" "}
                            {getCountryFromLocation(
                              nft._collectionCensus?.location
                            )}
                            )
                          </span>
                        </>
                      ) : null}

                      {type === COLLECTION_TYPE.experience ? (
                        <>
                          <span className="h4 regular white-color">
                            {getCityFromLocation(
                              nft.payload?.location ??
                                nft._collectionCensus?.location
                            )}{" "}
                            (
                            {getCityShortFromLocation(
                              nft.payload?.location ??
                                nft._collectionCensus?.location
                            )}
                            ,{" "}
                            {getRegionFromLocation(
                              nft.payload?.location ??
                                nft._collectionCensus?.location
                            )}
                            ,{" "}
                            {getCountryFromLocation(
                              nft.payload?.location ??
                                nft._collectionCensus?.location
                            )}
                            )
                          </span>
                        </>
                      ) : null}

                      <div className="height-40"></div>

                      <div className="h100 only-mobile"></div>

                      <div
                        className="horizontal-divider m-0 only-desktop"
                        style={{ maxWidth: "300px" }}
                      ></div>

                      <div className="height-12"></div>

                      <div className="white-color h4 regular m-0">
                        <div className="d-flex gap-2 align-items-center">
                          <Icon icon="calendar_today" />
                          {type === COLLECTION_TYPE.hotel || !type ? (
                            <>
                              {datetimeRangeToString(
                                nft.payload?.checkin,
                                nft.payload?.checkout,
                                nft._collectionCensus?.location?.gmt
                              )}
                            </>
                          ) : null}

                          {type === COLLECTION_TYPE.experience ? (
                            <>
                              {datetimeToString(
                                nft.payload?.date,
                                nft._collectionCensus?.location?.gmt,
                                false,
                                true
                              )}
                            </>
                          ) : null}
                        </div>

                        <div className="height-12"></div>

                        <div className="d-flex gap-2 align-items-center">
                          <Icon icon="group" />
                          {getAmountOfGuests(nft).adults}{" "}
                          {getAmountOfGuests(nft).childs > 0
                            ? `(+${getAmountOfGuests(nft).adults})`
                            : ""}
                          <span>{t("nftpage.guests")}</span>
                        </div>

                        <div className="height-12"></div>

                        {type === COLLECTION_TYPE.hotel || !type ? (
                          <>
                            {boards[board] ? (
                              <div className="d-flex gap-2 align-items-center">
                                <Icon
                                  fill
                                  type="symbol"
                                  icon={board === "RO" ? "no_meals" : "coffee"}
                                />
                                <span className="h4 regular white-color">
                                  {t(boards[board])}
                                </span>
                              </div>
                            ) : null}
                          </>
                        ) : null}

                        {type === COLLECTION_TYPE.experience ? (
                          <>
                            <div className="d-flex gap-2 align-items-center">
                              <Icon fill type="symbol" icon="alarm" />
                              <span className="h4 regular white-color">
                                {formatMinutes(nft.payload?.duration)}
                              </span>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <div className="horizontal-divider m-0"></div>

        <div id="nft-page-action-bar">{actionBar}</div>
      </div>

      <div
        className="d-flex flex-column white-background"
        style={{ width: "100vw" }}
      >
        <div className="container">
          <div className="content">
            <div className="height-60"></div>

            {type === COLLECTION_TYPE.hotel || !type ? (
              <>
                <span className="bodytext regular dark-grey-color">
                  {getCityFromLocation(nft._collectionCensus?.location)} (
                  {getCityShortFromLocation(nft._collectionCensus?.location)},{" "}
                  {getRegionFromLocation(nft._collectionCensus?.location)},{" "}
                  {getCountryFromLocation(nft._collectionCensus?.location)})
                </span>
              </>
            ) : null}

            {type === COLLECTION_TYPE.experience ? (
              <>
                <span className="bodytext regular dark-grey-color">
                  {getCityFromLocation(
                    nft.payload?.location ?? nft._collectionCensus?.location
                  )}{" "}
                  (
                  {getCityShortFromLocation(
                    nft.payload?.location ?? nft._collectionCensus?.location
                  )}
                  ,{" "}
                  {getRegionFromLocation(
                    nft.payload?.location ?? nft._collectionCensus?.location
                  )}
                  ,{" "}
                  {getCountryFromLocation(
                    nft.payload?.location ?? nft._collectionCensus?.location
                  )}
                  )
                </span>
              </>
            ) : null}

            <div className="height-8"></div>

            <div className="d-flex gap-2 align-items-center">
              {type === COLLECTION_TYPE.hotel || !type ? (
                <>
                  <span className="h3 medium m-0">
                    {nft._collectionCensus?.name}
                  </span>
                  {nft._collectionCensus?.stars ? (
                    <Stars stars={nft._collectionCensus?.stars} />
                  ) : null}
                </>
              ) : null}

              {type === COLLECTION_TYPE.experience ? (
                <>
                  <span className="h3 medium m-0">
                    {expName ?? nft.payload?.category}
                  </span>
                </>
              ) : null}
            </div>

            <div className="height-8"></div>

            <p className="bodytext light dark-grey-color">
              {t("nftpage.property_of")} {nft._ownerName} |{" "}
              {t("nftpage.verified_by")}{" "}
              <span>
                {link !== "" ? (
                  <a
                    className="mb-0 linktext text-nowrap text-black"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      fireTagManagerEvent("open_partner");
                      CollectionController.clickOnCollection(nft._id);
                      handleButtonClick(link);
                    }}
                  >
                    {nft._collectionCensus?.name}
                  </a>
                ) : (
                  nft._collectionCensus?.name
                )}
              </span>
            </p>

            <div className="height-40"></div>
            <div className="horizontal-divider m-0"></div>
            <div className="height-40"></div>

            {type === COLLECTION_TYPE.hotel || !type ? (
              <>
                <div className="d-flex gap-3 flex-wrap">
                  <span className="d-flex gap-1">
                    <Icon fill type="symbol" icon="people" />
                    {getAmountOfGuests(nft).adults}{" "}
                    {getAmountOfGuests(nft).childs > 0
                      ? `(+${getAmountOfGuests(nft).adults})`
                      : ""}
                    <span>{t("nftpage.guests")}</span>
                  </span>
                  <div
                    style={{ margin: 0, height: "25px" }}
                    className="vertical-divider"
                  ></div>
                  <span className="d-flex gap-1">
                    <Icon fill type="symbol" icon="dark_mode" />
                    {getAmountOfNights(
                      nft.payload?.checkin,
                      nft.payload?.checkout
                    )}
                    <span>{t("nftpage.nights")}</span>
                  </span>
                  <div
                    style={{ margin: 0, height: "25px" }}
                    className="vertical-divider"
                  ></div>
                  <span className="d-flex gap-1">
                    <Icon fill type="symbol" icon="bed" />
                    {rooms.length > 0
                      ? rooms.length
                      : `1 ${t("nftpage.room")}`}{" "}
                    {rooms.map((r) => r.name).join(", ")}
                  </span>
                  <div
                    style={{ margin: 0, height: "25px" }}
                    className="vertical-divider"
                  ></div>
                  <span className="d-flex gap-1">
                    <Icon
                      fill
                      type="symbol"
                      icon={board === "RO" ? "no_meals" : "coffee"}
                    />
                    {t(boards[board])}
                  </span>
                </div>
              </>
            ) : null}

            {type === COLLECTION_TYPE.experience ? (
              <>
                <div className="d-flex gap-3 flex-wrap">
                  <span className="d-flex gap-1">
                    <Icon fill type="symbol" icon="event" />

                    <span>
                      {datetimeToString(
                        nft.payload?.date,
                        nft._collectionCensus?.location?.gmt,
                        false,
                        true
                      )}
                    </span>
                  </span>
                  <div
                    style={{ margin: 0, height: "25px" }}
                    className="vertical-divider"
                  ></div>
                  <span className="d-flex gap-1">
                    <Icon fill type="symbol" icon="people" />

                    <span>
                      {getAmountOfGuests(nft).adults} {t("nftpage.guests")}
                    </span>
                  </span>
                  <div
                    style={{ margin: 0, height: "25px" }}
                    className="vertical-divider"
                  ></div>
                  <span className="d-flex gap-1">
                    <Icon fill type="symbol" icon="alarm" />

                    <span>{formatMinutes(nft.payload?.duration)}</span>
                  </span>
                </div>
              </>
            ) : null}

            {type === COLLECTION_TYPE.hotel || !type ? (
              <>
                <div className="height-40"></div>
                <div className="d-flex bodytext gap-3">
                  <span className="d-flex flex-column flex-md-row gap-1">
                    <span className="medium">{t("app.nft.checkin")}:</span>{" "}
                    <span className="light dark-grey-color">
                      {datetimeToString(
                        nft.payload?.checkin,
                        nft._collectionCensus?.location?.gmt
                      )}
                    </span>
                  </span>
                  <div style={{ margin: 0 }} className="vertical-divider"></div>
                  <span className="d-flex flex-column flex-md-row gap-1">
                    <span className="medium">{t("app.nft.checkout")}:</span>{" "}
                    <span className="light dark-grey-color">
                      {datetimeToString(
                        nft.payload?.checkout,
                        nft._collectionCensus?.location?.gmt
                      )}
                    </span>
                  </span>
                </div>

                {description ? (
                  <>
                    <div className="height-32"></div>
                    <span className="bodytext regular dark-grey-color">
                      {description}
                    </span>
                  </>
                ) : null}

                {services.length > 0 ? (
                  <>
                    <div className="height-32"></div>
                    <span className="bodytext medium">
                      {t("app.nft.hotelservice")}
                    </span>
                    <div className="height-8"></div>

                    <div className="d-flex flex-wrap bodytext regular dark-grey-color">
                      {services.map((service, key) => {
                        return (
                          <div
                            key={key}
                            style={{ width: "50%" }}
                            className="d-flex gap-1 my-1"
                          >
                            <Icon fill icon={service.icon} />
                            <span className="text-ellipsis">
                              {service.value}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          {type === COLLECTION_TYPE.experience && expDescription ? (
            <>
              <div className="height-32"></div>
              <span className="bodytext regular dark-grey-color">
                {expDescription}
              </span>
            </>
          ) : null}

          {type === COLLECTION_TYPE.experience && hostName ? (
            <>
              <div className="height-32"></div>
              <span className="bodytext medium">
                {t("nftpage.host_name")}: {hostName}
              </span>
            </>
          ) : null}

          {type === COLLECTION_TYPE.experience && hostDescription ? (
            <>
              <br />
              <div className="height-8"></div>
              <span className="bodytext regular dark-grey-color">
                {hostDescription}
              </span>
            </>
          ) : null}

          <div className="height-40"></div>
          <div className="horizontal-divider m-0"></div>
          <div className="height-40"></div>

          {type === COLLECTION_TYPE.experience ? (
            <>
              <span className="h3">Tour</span>
              <div className="height-8"></div>
              <ul>
                {(nft.payload as payloadExperienceType).tour?.map((t, key) => {
                  return (
                    <li key={"tour_" + key}>
                      <span className="bodytext medium">{t.name}</span> <br />
                      <span className="bodytext light">
                        {t.location?.label}
                      </span>{" "}
                      -{" "}
                      <span className="bodytext light dark-grey-color">
                        {datetimeToString(
                          t.date,
                          nft._collectionCensus?.location?.gmt,
                          false,
                          true
                        )}
                      </span>
                    </li>
                  );
                })}
              </ul>
              <div className="height-40"></div>
              <div className="horizontal-divider m-0"></div>
              <div className="height-40"></div>
            </>
          ) : null}

          {type === COLLECTION_TYPE.hotel || !type ? (
            <>
              <section className="d-flex flex-column h100">
                {rooms.length === 0 ? (
                  <>
                    <span className="h3 medium">
                      {(nft.payload as payloadHotelType)?.rooms
                        ?.map((r) => r.name)
                        ?.join(", ")}
                    </span>
                  </>
                ) : null}

                {rooms.map((room, key) => {
                  return (
                    <div className="d-flex flex-column" key={"room_" + key}>
                      <span className="h3 medium">{room.name}</span>
                      <div className="height-8"></div>
                      <div className="d-flex flex-wrap bodytext regular dark-grey-color">
                        {room.traits?.map((service, key) => {
                          return (
                            <div
                              key={key}
                              style={{ width: "50%" }}
                              className="d-flex gap-1 my-1"
                            >
                              <Icon fill icon={service.icon} />
                              <span className="text-ellipsis">
                                {service.value}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="height-40"></div>

                      {room.images ? (
                        <div
                          style={{ height: isSmallScreen ? "100dvh" : "600px" }}
                          className="w100 d-flex"
                        >
                          <RoomImages images={room.images} />
                        </div>
                      ) : null}

                      <div className="height-40"></div>
                      <div className="d-flex flex-wrap bodytext regular">
                        {room.services?.map((service, key) => {
                          return (
                            <div
                              key={key}
                              style={{
                                width: isSmallScreen ? "100%" : "33%",
                                paddingRight: "15px",
                              }}
                              className="d-flex flex-column gap-1 my-2"
                            >
                              <span className="text-nowrap">
                                {service.value}
                              </span>
                              {isHtml(service.data) ? (
                                <div
                                  className="dark-grey-color list-none p-0 m-0"
                                  dangerouslySetInnerHTML={{
                                    __html: service.data,
                                  }}
                                ></div>
                              ) : null}

                              {service.data && !isHtml(service.data) ? (
                                <div className="dark-grey-color list-none p-0 m-0">
                                  {String(service.data)
                                    .split(",")
                                    .map((data, key) => {
                                      return (
                                        <p
                                          className="m-0"
                                          key={"service_" + key}
                                        >
                                          {data}
                                        </p>
                                      );
                                    })}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                      <div className="height-40"></div>
                    </div>
                  );
                })}
              </section>

              <div className="height-40"></div>
              <div className="horizontal-divider m-0"></div>
              <div className="height-40"></div>
            </>
          ) : null}

          <section className="d-flex flex-column">
            <span className="h3 medium d-flex gap-1">
              {nft._collectionCensus?.location?.label}
            </span>

            <div className="height-40"></div>

            <div style={{ height: isSmallScreen ? "100dvh" : "600px" }}>
              <NftMap nft={nft} />
            </div>

            <div className="d-flex flex-wrap">
              {keys?.map((key, kkey) => {
                return (
                  <div
                    className="p-3"
                    style={{ width: isSmallScreen ? "100%" : "33%" }}
                    key={"key_" + kkey}
                  >
                    <div className="height-40 only-desktop"></div>
                    <div className="d-flex gap-2 align-items-center">
                      <Icon icon={key} />
                      <span className="bodytext medium text-nowrap">
                        {key === "location_on" || key === "place" ? (
                          <>{t("app.nft.wherearea")}</>
                        ) : null}
                        {key === "directions_car" ? (
                          <>{t("app.nft.movearea")}</>
                        ) : null}
                        {key === "restaurant" ? (
                          <>{t("app.nft.eatarea")}</>
                        ) : null}

                        {key !== "location_on" &&
                        key !== "place" &&
                        key !== "directions_car" &&
                        key !== "restaurant"
                          ? "Extra"
                          : ""}
                      </span>
                    </div>
                    <div className="height-5"></div>

                    {poi
                      .filter((p) => p.icon === key)
                      .map((poi, pkey) => {
                        return (
                          <span
                            className="bodytext regular d-flex my-1 gap-1 dark-grey-color"
                            key={"poi_" + pkey}
                          >
                            {poi.value}
                          </span>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          </section>

          {type === COLLECTION_TYPE.hotel || !type ? (
            <>
              <div className="height-40"></div>
              <div className="horizontal-divider m-0"></div>
              <div className="height-40"></div>

              <div className="d-flex flex-column flex-md-row">
                <section className="d-flex flex-column w100">
                  <div className="d-flex flex-column flex-lg-row  gap-3">
                    {Object.keys(reviewSummary ?? {}).length > 0 ? (
                      <div className="w100">
                        <div className="d-flex gap-3 align-items-center">
                          <p className="h1-large regular m-0 light-black-color">
                            {reviewSummary?.vote}
                          </p>
                          <div>
                            <p className="m-0 bodytext-sm regular light-black-color">
                              {reviewSummary?.voteText}
                            </p>
                            <p className="m-0 bodytext-xs light light-black-color">
                              {t("app.nft.reviews")}
                            </p>
                          </div>
                        </div>

                        <div className="height-32"></div>

                        {reviewSummary?.cleanlinessVote ? (
                          <>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 bodytext light dark-grey-color">
                                {t("app.nft.cleanliness")}
                              </p>
                              <p className="m-0 bodytext light light-black-color">
                                {reviewSummary?.cleanlinessVote}/5
                              </p>
                            </div>
                            <progress
                              className="w100"
                              value={Number(
                                Number(reviewSummary?.cleanlinessVote).toFixed(
                                  2
                                )
                              )}
                              max="5"
                            ></progress>
                            <div className="height-12"></div>
                          </>
                        ) : null}

                        {reviewSummary?.hotelConditionVote ? (
                          <>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 bodytext light dark-grey-color">
                                {t("app.nft.conditions")}
                              </p>
                              <p className="m-0 bodytext light light-black-color">
                                {reviewSummary?.hotelConditionVote}/5
                              </p>
                            </div>
                            <progress
                              className="w100"
                              value={Number(
                                Number(
                                  reviewSummary?.hotelConditionVote
                                ).toFixed(2)
                              )}
                              max="5"
                            ></progress>
                            <div className="height-12"></div>
                          </>
                        ) : null}

                        {reviewSummary?.roomConfortVote ? (
                          <>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 bodytext light dark-grey-color">
                                {t("app.nft.roomcomfort")}
                              </p>
                              <p className="m-0 bodytext light light-black-color">
                                {reviewSummary?.roomConfortVote}/5
                              </p>
                            </div>
                            <progress
                              className="w100"
                              value={Number(
                                Number(reviewSummary?.roomConfortVote).toFixed(
                                  2
                                )
                              )}
                              max="5"
                            ></progress>
                            <div className="height-12"></div>
                          </>
                        ) : null}

                        {reviewSummary?.serviceStaffVote ? (
                          <>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 bodytext light dark-grey-color">
                                {t("app.nft.service")}
                              </p>
                              <p className="m-0 bodytext light light-black-color">
                                {reviewSummary?.serviceStaffVote}/5
                              </p>
                            </div>
                            <progress
                              className="w100"
                              value={Number(
                                Number(reviewSummary?.serviceStaffVote).toFixed(
                                  2
                                )
                              )}
                              max="5"
                            ></progress>
                            <div className="height-12"></div>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <span>{t("app.nft.noreviews")}</span>
                    )}
                  </div>
                </section>

                <div
                  style={{
                    maxHeight: isSmallScreen ? "" : "500px",
                    overflowY: "scroll",
                  }}
                  className={`w100 ${isSmallScreen ? "p-1" : "p-5"}`}
                >
                  {reviews?.map((review, key) => {
                    return (
                      <div key={"reivew_" + key}>
                        <p className="m-0 bodytext-sm medium">
                          {review.voteText}
                        </p>

                        <div className="height-8"></div>

                        <p className="m-0 bodytext-xs light light-black-color">
                          {datetimeToString(review.date)}
                        </p>

                        <div className="height-8"></div>

                        <p className="m-0 bodytext-xs light light-black-color">
                          {review.context}
                        </p>

                        <div className="height-8"></div>

                        <p className="m-0 bodytext light dark-grey-color">
                          {review.text}
                        </p>

                        <div className="height-32"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="height-40"></div>
      </div>
      <div className="container">
        <div className="content">
          <div className="height-100"></div>
          <p className="h3 medium white-color">{t("nftpage.similar")}</p>
          <div className="height-14"></div>
          <TakCarousel nfts={relatedNfts} scroll />
          <div className="height-100"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
