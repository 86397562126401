import { useTranslation } from "react-i18next";
import { iStripeBankAccount } from "../../../../assets/models/iStripe";
import { useState } from "react";
import StripeController from "../../../../assets/Controllers/StripeController";
import UserController from "../../../../assets/Controllers/UserController";
import { apiErrorToast } from "../../../../assets/Utils/errors";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { closeModal } from "../../../../assets/Utils/modal";

export default function RemoveBankAccount({
  bankAccount,
}: {
  bankAccount: iStripeBankAccount;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteAccount = async (id: string) => {
    setIsLoading(true);
    try {
      await StripeController.deleteBankAccount({ bankAccountId: id });
      await UserController.initLoggedUser();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="d-flex flex-column p-4">
      <span className="bodytext medium">
        {t("wallet.earnings.deletebanlaccount")}
      </span>
      <div className="height-16"></div>
      <span>{t("wallet.earnings.deletebankaccountconfirmation")}</span>
      <div className="height-16"></div>

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            deleteAccount(bankAccount.id);
            closeModal();
          }}
          text={t("wallet.earnings.deletebankaccount")}
        />
      </div>
    </div>
  );
}
